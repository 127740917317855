import React, { useState, useEffect, useCallback, useRef, useMemo } from "react"
import { withPrefix } from "gatsby"
import Helmet from "react-helmet"
import Select from "./Select/index"
import Loader from "react-loader-spinner"
import Axios from "axios"
import { sort } from "fast-sort"

function naturalCompare(a, b) {
  const ax = []
  const bx = []

  a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
    ax.push([$1 || Infinity, $2 || ""])
  })
  b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
    bx.push([$1 || Infinity, $2 || ""])
  })

  while (ax.length && bx.length) {
    const an = ax.shift()
    const bn = bx.shift()
    const nn = an[0] - bn[0] || an[1].localeCompare(bn[1])
    if (nn) return nn
  }

  return ax.length - bx.length
}

const initialState = {
  initialData: [],
  filter: undefined,
  loadMore: 20,
  show: false,
  loading: false,
  courses: [],
  ages: [],
  classes: [],
  ageranges: [],
  athleteType: undefined,
  filterCourse: undefined,
  filterGender: undefined,
  filterAge: undefined,
  filterClass: undefined,
  filterAgeRange: undefined,
}
const OrderH = {
  "50 free": 1,
  "100 free": 2,
  "200 free": 3,
  "400 free": 4,
  "800 free": 5,
  "1500 free": 6,
  "50 back": 7,
  "100 back": 8,
  "200 back": 9,
  "50 breast": 10,
  "100 breast": 11,
  "200 breast": 12,
  "50 fly": 13,
  "100 fly": 14,
  "200 fly": 15,
  "100 IM": 16,
  "150 IM": 17,
  "200 IM": 18,
  "400 IM": 19,
  Relay: 20,
  Last: 21,
}
const orderListWeight = arrayOfObjects => {
  const descTags = sort(arrayOfObjects).asc(
    tag => OrderH[tag.orderDataField] || 0
  )
  return descTags
}

const ListContent = () => {
  const [loading, setLoading] = useState(false)
  const [children, setChildren] = useState([])
  const [initialData, setInitialData] = useState([])
  const [data, setData] = useState([])

  const [state, setState] = useState(initialState)
  const getType = cat => {
    if (cat === "Freestyle") {
      return "free"
    } else if (cat === "Backstroke") {
      return "back"
    } else if (cat === "Breaststroke") {
      return "breast"
    } else if (cat === "Butterfly") {
      return "fly"
    } else if (cat === "Individual Medley") {
      return "IM"
    } else if (cat === "Individal Medley") {
      return "IM"
    } else if (cat.includes("Relay")) {
      return "Relay"
    } else {
      return "Last"
    }
  }

  useEffect(() => {
    setLoading(true)
    Axios.get(`${process.env.GATSBY_HOST_API_R}`).then(data => {
      let orderData = data.data.data.map(item => ({
        ...item,
        orderDataField: `${item.Distance} ${getType(item.Category)}`,
      }))
      orderData = orderListWeight(orderData)
      setChildren(orderData)
      setData(orderData)
      setInitialData(orderData)
    })
  }, [])

  useEffect(() => {
    const allCourses = children.map(item => item.Course)
    const uniqueCourses = [...new Set(allCourses)].filter(
      item => item && item.length > 0
    )
    const ageGroup = children
      .filter(item => {
        return item.AthleteType === "Age Group"
      })
      .sort()
    const allAges = ageGroup.map(item => item.Age)
    const uniqueAges = [...new Set(allAges)]
      .filter(item => item && item.length > 0)
      .sort()

    const para = children.filter(item => {
      return item.AthleteType === "Para"
    })
    const allClasses = para.map(item => item.Class)
    const uniqueClasses = [...new Set(allClasses)]
      .filter(item => item && item.length > 0)
      .sort(naturalCompare)

    const masters = children.filter(item => {
      return item.AthleteType === "Masters"
    })
    const allAgeRanges = masters.map(item => item.Agerange)
    const uniqueAgeRanges = [...new Set(allAgeRanges)]
      .filter(item => item && item.length > 0)
      .sort(naturalCompare)
    setState(state => ({
      ...state,
      courses: uniqueCourses,
      ageranges: uniqueAgeRanges,
      ages: uniqueAges,
      classes: uniqueClasses,
    }))
    setLoading(false)
  }, [children])

  const onClick = event => {
    const { filter } = state
    let type = event.target.dataset.type
    const show = !(filter === type)
    if (filter === type) {
      type = undefined
    }

    let title = ""
    if (type === "age group") {
      title = "Age"
    } else if (type === "para") {
      title = "Class"
    } else if (type === "masters") {
      title = "Age Range"
    }

    setState(state => ({
      ...state,
      athleteType: title,
      filter: type,
      show: show,
    }))
  }

  const selectAge = useRef(null)
  const selectClass = useRef(null)
  const selectAgeRange = useRef(null)
  const selectGender = useRef(null)
  const selectCourse = useRef(null)

  useEffect(() => {
    const {
      filterGender,
      filterCourse,
      filterAge,
      filterClass,
      filterAgeRange,
      filter,
    } = state
    let dataFilter = initialData.filter((item, index) => {
      return (
        !filter || filter === "all" || item.AthleteType.toLowerCase() === filter
      )
    })
    dataFilter = dataFilter.filter((item, index) => {
      return (
        !filterGender ||
        filterGender === "all" ||
        item.Gender.toLowerCase() === filterGender
      )
    })
    dataFilter = dataFilter.filter((item, index) => {
      return (
        !filterCourse || filterCourse === "all" || item.Course === filterCourse
      )
    })

    if (filter === "age group") {
      dataFilter = dataFilter.filter((item, index) => {
        return !filterAge || filterAge === "all" || item.Age === filterAge
      })
    } else if (filter === "para") {
      dataFilter = dataFilter.filter((item, index) => {
        return (
          !filterClass || filterClass === "all" || item.Class === filterClass
        )
      })
    } else if (filter === "masters") {
      dataFilter = dataFilter.filter((item, index) => {
        return (
          !filterAgeRange ||
          filterAgeRange === "all" ||
          item.Agerange === filterAgeRange
        )
      })
    }
    dataFilter = orderListWeight(dataFilter)
    setData(dataFilter)
    setLoading(false)
  }, [state])

  const onClickMore = event => {
    const min = 0.5
    const max = 1.8
    const rand = Math.floor(Math.random() * (max - min + 0.7) + min)

    setLoading(true)

    setTimeout(function() {
      setLoading(false)
      setState(state => ({ ...state, loadMore: state.loadMore + 20 }))
    }, rand * 1000)
  }

  const getLoadMore = () => {
    const total = data ? data.length : 0
    if (total < 20) {
      return null
    }
    if (state.loadMore >= total) {
      return <></>
    }
    return (
      <button onClick={onClickMore} className="button-load-more">
        Load More
      </button>
    )
  }

  const handleChange = useCallback(
    ({ name, value }) => {
      console.log(state)
      const tmp = state
      tmp[name] = value
      console.log(tmp)
      console.log({ ...state, tmp })
      setState(state => ({ ...state, tmp }))
    },
    [state]
  )

  const shouldShow = useMemo(() => {
    if (state.athleteType === "Class") {
      return !!state.filterGender && !!state.filterClass
    } else if (state.athleteType === "Age Range") {
      return !!state.filterAgeRange && !!state.filterGender
    } else {
      return !!state.filterAge && !!state.filterGender
    }
  }, [
    state,
    state.athleteType,
    state.filterAge,
    state.filterAgeRange,
    state.filterGender,
  ])

  return (
    <>
      <section className="records-grid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {" "}
              <div className="select-type-area">
                <h3>Please select the athlete type</h3>
                <div className="select-type-items">
                  <button
                    className={
                      state.filter === "age group"
                        ? "theme-btn active"
                        : "theme-btn"
                    }
                    data-type="age group"
                    onClick={onClick}
                  >
                    Age group
                  </button>
                  <button
                    className={
                      state.filter === "para" ? "theme-btn active" : "theme-btn"
                    }
                    data-type="para"
                    onClick={onClick}
                  >
                    Para
                  </button>
                  <button
                    className={
                      state.filter === "masters"
                        ? "theme-btn active"
                        : "theme-btn"
                    }
                    data-type="masters"
                    onClick={onClick}
                  >
                    Masters
                  </button>
                </div>
              </div>
              <div className={state.show ? "table-area" : "table-area d-none"}>
                <div className="select-items-area">
                  <div className="box">
                    <h6>Gender</h6>
                    <Select
                      ref={selectGender}
                      name="filterGender"
                      placeholder="-"
                      value={state.filterGender}
                      handleChange={handleChange}
                      selectOptions={["all", "male", "female"]}
                    />
                  </div>
                  <div className="box">
                    <h6>{state.athleteType}</h6>
                    {state.athleteType === "Age" && (
                      <Select
                        ref={selectAge}
                        className="select-age"
                        name="filterAge"
                        handleChange={handleChange}
                        placeholder="-"
                        value={state.filterAge}
                        selectOptions={["all"].concat(
                          state.ages.map((item, index) => `${item}`)
                        )}
                      />
                    )}
                    {state.athleteType === "Class" && (
                      <Select
                        ref={selectClass}
                        className="select-class"
                        name="filterClass"
                        handleChange={handleChange}
                        placeholder="-"
                        value={state.filterClass}
                        selectOptions={["all"].concat(
                          state.classes.map((item, index) => `${item}`)
                        )}
                      />
                    )}
                    {state.athleteType === "Age Range" && (
                      <Select
                        ref={selectAgeRange}
                        className="select-agerange"
                        name="filterAgeRange"
                        handleChange={handleChange}
                        placeholder="-"
                        value={state.filterAgeRange}
                        selectOptions={["all"].concat(
                          state.ageranges.map((item, index) => `${item}`)
                        )}
                      />
                    )}
                  </div>
                  <div className="box">
                    <h6>Course</h6>
                    <Select
                      ref={selectCourse}
                      name="filterCourse"
                      handleChange={handleChange}
                      placeholder="-"
                      value={state.filterCourse}
                      selectOptions={["all"].concat(
                        state.courses.map((item, index) => `${item}`)
                      )}
                    />
                  </div>
                </div>

                <div style={{ overflowX: "auto" }}>
                  {shouldShow && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Event</th>
                          <th scope="col">Course</th>
                          <th scope="col">Athlete</th>
                          <th scope="col">Club</th>
                          <th scope="col">Time</th>
                          <th scope="col">Year</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((item, index) => {
                          if (index <= state.loadMore) {
                            return (
                              <tr>
                                <td>
                                  {item.Distance}
                                  {"m "}
                                  {item.Category}
                                </td>
                                <td>{item.Course}</td>
                                <th scope="row">{item.Athlete}</th>
                                <td>{item.Club}</td>
                                <td>{item.Time}</td>
                                <td>{item.Year}</td>
                              </tr>
                            )
                          }
                          return ""
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={state.show && shouldShow ? "row" : "row d-none"}>
            <div className="col-12 align-items-center text-center">
              {loading && (
                <>
                  <Loader
                    type="Puff"
                    color="#00aad8"
                    height={100}
                    width={100}
                  />
                  <br />
                </>
              )}
              {getLoadMore()}
            </div>
          </div>
        </div>
      </section>
      <Helmet>
        <script
          src={withPrefix("scripts/athletesrecords.js")}
          type="text/javascript"
        />
      </Helmet>
    </>
  )
}

export default ListContent
