import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import ListContent from "../../components/list"

const CompetitionsCampsPage = ({ data }) => {
  const page = data.wagtail.page

  let _ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach((item) => {
    if ("children" in item) {
      _ancestor = item
    }
  })

  return (
    <>
      <Layout
        isContent={true}
        subMenu={_ancestor.children}
        button={_ancestor.button}
        parent={"athletes"}
        siteTitle={page.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/athletes">Athletes</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="hero-heading">{page.title}</h1>
                <p>{page.descriptionSimple}</p>
              </div>
            </div>
            {page.feedImage && (
              <section className="hero-area">
                <img src={page.feedImage?.src || ""} alt="feed image" />
              </section>
            )}
          </div>
        </section>
        <ListContent />
        {data.wagtail.page.fullContent &&
          data.wagtail.page.fullContent.length > 0 && (
            <CTABlock data={page.fullContent[0]}></CTABlock>
          )}
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    wagtail {
      page(slug: "records") {
        ... on AthletePage {
          descriptionSimple
          __typename
          id
          body
          feedImage {
            src
            height
            width
          }
          slug
          ancestors {
            ... on AthletesPage {
              __typename
              children {
                title
                slug
                ... on AthletePage {
                  live
                  isProtected
                }
                ... on EventPage {
                  live
                  isProtected
                }
                ... on SimplePage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default CompetitionsCampsPage
